import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText, Heading,
  Input, InputGroup, SimpleGrid, Text
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const CardDetailsStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();

  const schema = yup.object({
    first_six_digits: yup.string()
      .transform(val => val || null)
      .nullable()
      .test('len', t('fields.first_six_digits.validations.length'), val => `${val}`.length === 6)
      .matches(/^[0-9]+$/, t('fields.last_four_digits.validations.required'))
      .required(t('fields.first_six_digits.validations.length')),
    last_four_digits: yup.string()
      .transform(val => val || null)
      .nullable()
      .test('len', t('fields.last_four_digits.validations.length'), val => `${val}`.length === 4)
      .matches(/^[0-9]+$/, t('fields.last_four_digits.validations.required'))
      .required(t('fields.last_four_digits.validations.length')),
  });

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: stepValues()?.payment_method_attributes || stepValues(),
  });

  const [onSubmit] = useThrottle((data: any) => {
    goForward({
      payment_method_attributes: data,
    });
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="lg" mb="4">{ t('steps.refund_card_details.title') }</Heading>
      <Text fontSize={13} mt={-2} mb={6}>{ t('steps.refund_card_details.text') }</Text>

      <SimpleGrid columns={2} spacing={5}>
        <FormControl id="first_six_digits" isInvalid={!!errors.first_six_digits}>
          <InputGroup>
            <Input
              type="text"
              maxLength="6"
              defaultValue={getValues('first_six_digits')}
              {...register('first_six_digits')}
            />
          </InputGroup>

          { errors.first_six_digits ?
            <FormErrorMessage>{ errors.first_six_digits.message }</FormErrorMessage> :
            <FormHelperText>{ t('fields.first_six_digits.aside') }</FormHelperText>
          }
        </FormControl>

        <FormControl id="last_four_digits" isInvalid={!!errors.last_four_digits}>
          <InputGroup>
            <Input
              type="text"
              maxLength="4"
              defaultValue={getValues('last_four_digits')}
              {...register('last_four_digits')}
            />
          </InputGroup>

          { errors.last_four_digits ?
            <FormErrorMessage>{ errors.last_four_digits.message }</FormErrorMessage> :
            <FormHelperText>{ t('fields.last_four_digits.card_aside') }</FormHelperText>
          }
        </FormControl>
      </SimpleGrid>

      <Flex justifyContent="space-between" mt="3">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default CardDetailsStep;
