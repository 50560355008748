import { Button, VStack, Flex, Text, Heading, HStack, IconButton, Center } from '@chakra-ui/react';
import { AddIcon, ArrowForwardIcon, MinusIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';

const RateStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack, disableBack } = useFunnel();

  const { handleSubmit, getValues } = useForm<any>({
    defaultValues: stepValues()
  });
  const [ rating, setRating ] = useState(getValues('rating') || 8);

  const ratings = [
    [t('fields.rating.options.poor'), '&#128544;'],
    [t('fields.rating.options.poor'), '&#128544;'],
    [t('fields.rating.options.poor'), '&#128544;'],
    [t('fields.rating.options.poor'), '&#128544;'],
    [t('fields.rating.options.average'), '&#128528;'],
    [t('fields.rating.options.okay'), '&#128566;'],
    [t('fields.rating.options.okay'), '&#128566;'],
    [t('fields.rating.options.good'), '&#128578;'],
    [t('fields.rating.options.great'), '&#128522;'],
    [t('fields.rating.options.excellent'), '&#128525;'],
  ];

  const [onSubmit] = useThrottle(() => {
    goForward({ rating });
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="4">{ t('steps.rate.title') }</Heading>

      <VStack
        py="8"
        spacing="8"
        backgroundColor="gray.100"
        borderRadius="2xl"
        boxShadow="xl"
      >
        <Heading size="lg" fontWeight="semibold">{ ratings[rating - 1][0] }</Heading>
        <Text fontSize="8xl" lineHeight="1" dangerouslySetInnerHTML={{ __html: ratings[rating - 1][1] }}></Text>
        <HStack>
          <IconButton
            aria-label={t('fields.rating.aria_label.decrease_rating')}
            icon={<MinusIcon />}
            disabled={rating === 1}
            onClick={() => setRating(rating - 1)}
          />
          <Center minW="14">
            <Text fontSize="2xl" fontWeight="semibold">{rating}</Text>
          </Center>
          <IconButton
            aria-label={t('fields.rating.aria_label.increase_rating')}
            icon={<AddIcon />}
            disabled={rating === 10}
            onClick={() => setRating(rating + 1)}
          />
        </HStack>
      </VStack>

      <Flex justifyContent={ disableBack ? 'right' : 'space-between'} mt="6">
        { !disableBack && <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button> }
        <Button
          variant="solid"
          size="lg"
          type="submit"
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RateStep;
