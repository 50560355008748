import {
  StackDivider,
  VStack,
  RadioGroup,
  Radio,
  Flex,
  Button,
  FormLabel,
  FormControl, Heading
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const RefundInteracQuestion = () => {
  const { t, translations } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle((data: any) => {
    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="4">{ t('steps.refund_interac_question.title') }</Heading>

      <FormControl id="interac_question" isInvalid={!!errors.interac_question}>
        <FormLabel>{ t('fields.interac_question.label') }</FormLabel>
        <RadioGroup defaultValue={getValues('interac_question')}>
          <VStack
            spacing="0"
            mb="4"
            overflow="hidden"
            backgroundColor="gray.100"
            borderRadius="2xl"
            boxShadow="xl"
            alignItems="left"
            divider={<StackDivider borderColor="gray.300" />}
          >
            {
              translations.fields.interac_question.questions.map((option, index) =>
                <Radio
                  key={index}
                  value={String(option)}
                  px="5" py="4"
                  {...register('interac_question', { required: true })}
                >{option}</Radio>
              )
            }
          </VStack>
        </RadioGroup>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundInteracQuestion;
