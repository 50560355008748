import { Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AutoResizeTextarea from '../controls/AutoResizeTextarea';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';

const FeedbackStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack, values } = useFunnel();

  const schema = yup.object({
    description: yup.string()
  });

  const { register,getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle((data: any) => {
    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="description" isInvalid={!!errors.description}>
        <FormLabel variant="heavy" mb="2">{ t('fields.feedback.label') }</FormLabel>
        <FormLabel fontSize="sm" mb="4">{ t('fields.feedback.text', { rating: values.rating }) }</FormLabel>
        <AutoResizeTextarea
          maxLength={500}
          defaultValue={getValues('description')}
          {...register('description')}
        />
        { errors.description ?
          <FormErrorMessage>{ errors.description.message }</FormErrorMessage> :
          <FormHelperText>{ t('fields.feedback.aside') }</FormHelperText>
        }
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default FeedbackStep;
