import { Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AutoResizeTextarea from '../controls/AutoResizeTextarea';
import { usePageData } from 'lib/hooks/PageDataContext';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';

const DescriptionStep = () => {
  const { t, i18n } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();
  const { owner } = usePageData();

  const schema = yup.object({
    description: yup.string().when('$required', (required, schema) => required ? schema.required(t('fields.description.validations.required')) : schema)
  });

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    defaultValues: stepValues(),
    resolver: yupResolver(schema),
    context: {
      required: owner.require_description_flg,
    }
  });

  const [onSubmit] = useThrottle((data: any) => {
    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="description" isInvalid={!!errors.description}>
        <FormLabel variant="heavy">{ t('fields.description.label') }</FormLabel>
        { i18n.exists('fields.description.text') && <Text fontSize={13} color="gray" mt={-2} mb={3}>{ t('fields.description.text') }</Text>}
        <AutoResizeTextarea
          maxLength={500}
          defaultValue={getValues('description')}
          {...register('description')}
        />
        { errors.description ?
          <FormErrorMessage>{ errors.description.message }</FormErrorMessage> :
          <FormHelperText>{ t('fields.description.aside') }</FormHelperText>
        }
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default DescriptionStep;
