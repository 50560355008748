import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { useThrottle } from 'rooks';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const RefundInteracDetails = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack, values } = useFunnel();

  const schema = yup.object().shape({
    interac_answer: yup.string()
      .required(t('common.validations.required'))
      .min(3, t('fields.interac_answer.validations.min'))
      .matches(/^[a-zA-ZÀ-ž0-9]*$/, t('fields.interac_answer.validations.match')),
  });

  const { register, getValues, handleSubmit, control, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle(async (data: any) => {
    goForward(data);
  }, 1000);

  const interacAnswer = useWatch({ control, name: 'interac_answer' });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="4">{ t('steps.refund_interac_answer.title') }</Heading>

      <FormControl id="interac_answer" isInvalid={!!errors.interac_answer}>
        <FormLabel>{ t('fields.interac_answer.label') }</FormLabel>
        <Input
          type="text"
          maxLength={50}
          defaultValue={getValues('interac_answer')}
          {...register('interac_answer')}
        />

        <FormErrorMessage>{ errors.interac_answer?.message }</FormErrorMessage>
      </FormControl>

      <Text>
        { t('fields.interac_answer.text', {
          'question': values.interac_question,
          'answer': interacAnswer || '—',
        }) }
      </Text>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid }
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundInteracDetails;
