import {
  Alert, AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useThrottle } from 'rooks';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { usePageData } from 'lib/hooks/PageDataContext';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';
import { useState } from 'react';
import { useCheckZelleAlias } from '../../../../lib/hooks/UseCheckZelleAlias';

const RefundZelleDetailsStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack, isSubmitting } = useFunnel();
  const { owner } = usePageData();
  const [ zelleEnrollmentMethod, setZelleEnrollmentMethod ] = useState(stepValues()?.zelle_enrollment_method || null);
  const { isLoading: isCheckingAlias, checkAlias, hasError: hasAliasError, data: aliasData, reset: resetAliasCheck } = useCheckZelleAlias();

  const schema = yup.object().shape({
    zelle_enrollment_method: yup.string(),
    refund_first_name: yup.string().required(t('common.validations.required')),
    refund_last_name: yup.string().required(t('common.validations.required')),
    refund_email: yup.string().nullable().when('zelle_enrollment_method', {
      is: (method) => !method || method === 'email',
      then: yup.string().nullable().required(t('common.validations.required')).email(t('common.validations.email'))
    }),
    refund_phone: yup.string().nullable().when('zelle_enrollment_method', {
      is: (method) => !method || method === 'mobile',
      then: yup.string().nullable().required(t('common.validations.required')).phone(owner.country_code, t('common.validations.phone'))
    }),
  });

  const { register, getValues, setValue, handleSubmit, trigger, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle(async (data: any) => {
    if (!aliasData?.is_enrolled) {
      return;
    }

    if (data.zelle_enrollment_method === 'mobile') {
      data.refund_email = null;
    } else {
      data.refund_phone = null;
    }

    goForward(data);
  }, 1000);

  const [triggerCheckAlias] = useThrottle(async () => {
    await trigger(['refund_email', 'refund_phone']);

    if (errors.refund_phone || errors.refund_email) {
      return;
    }

    const data = await checkAlias({
      enrollment_method: zelleEnrollmentMethod,
      alias: zelleEnrollmentMethod === 'mobile' ? getValues('refund_phone') : getValues('refund_email'),
    });

    setValue('refund_first_name', data?.first_name);
    setValue('refund_last_name', data?.last_name);
    await trigger();
  }, 500);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="4">{ t('steps.refund_zelle.title') }</Heading>

      <FormControl id="zelle_enrollment_method" isInvalid={!!errors.zelle_enrollment_method}>
        <FormLabel>{ t('fields.zelle_enrollment_method.label') }</FormLabel>

        <ButtonGroup colorScheme={!getValues('zelle_enrollment_method') && isSubmitted ? 'red' : 'brand'}>
          <Button variant={ zelleEnrollmentMethod === 'mobile' ? 'solid' : 'border'}
                  onClick={() => {
                    setValue('zelle_enrollment_method', 'mobile');
                    setZelleEnrollmentMethod('mobile');
                    resetAliasCheck();
                  }}>
            { t('fields.refund_phone.label') }
          </Button>
          <Button variant={ zelleEnrollmentMethod === 'email' ? 'solid' : 'border'}
                  onClick={() => {
                    setValue('zelle_enrollment_method', 'email');
                    setZelleEnrollmentMethod('email');
                    resetAliasCheck();
                  }}>
            { t('fields.email.label') }
          </Button>
        </ButtonGroup>
      </FormControl>

      { zelleEnrollmentMethod === 'mobile' && <>
        <FormControl id="refund_phone" isInvalid={!!errors.refund_phone}>
          <Flex alignItems="center">
            <InputGroup>
              <Input
                type="tel"
                maxLength={50}
                placeholder={ t('fields.refund_phone.placeholder') }
                defaultValue={getValues('refund_phone')}
                {...register('refund_phone', {
                  onBlur: () => triggerCheckAlias(),
                })}
              />
              { isCheckingAlias &&  <InputRightElement><Spinner emptyColor="gray.200" color="blue.500" /></InputRightElement> }
            </InputGroup>
            <Button variant="solid" size="sm" ml="4" onClick={() => triggerCheckAlias()} disabled={isCheckingAlias}>
              { t('common.buttons.verify') }
            </Button>
          </Flex>

          <FormErrorMessage>{ errors.refund_phone?.message }</FormErrorMessage>
        </FormControl>
      </>}

      { zelleEnrollmentMethod === 'email' && <>
        <FormControl id="refund_email" isInvalid={!!errors.refund_email}>
          <Flex alignItems="center">
            <InputGroup>
              <Input
                type="email"
                maxLength={50}
                placeholder={ t('fields.refund_email.placeholder') }
                defaultValue={getValues('refund_email')}
                {...register('refund_email', {
                  onBlur: () => triggerCheckAlias()
                })}
              />
              { isCheckingAlias &&  <InputRightElement><Spinner emptyColor="gray.200" color="blue.500" /></InputRightElement> }
            </InputGroup>
            <Button variant="solid" size="sm" ml="4" onClick={() => triggerCheckAlias()} disabled={isCheckingAlias}>
              { t('common.buttons.verify') }
            </Button>
          </Flex>

          <FormErrorMessage>{ errors.refund_email?.message }</FormErrorMessage>
        </FormControl>
      </>}

      { getValues('refund_first_name') && getValues('refund_last_name') && <>
        <Alert borderRadius="md" status="success" mb="4" alignItems="start">
          <AlertIcon boxSize='22px' />
          <Text fontSize={15}>{ t('steps.refund_zelle.enrolled_message', {
            first_name: getValues('refund_first_name'),
            last_name: getValues('refund_last_name')
          })}
          </Text>
        </Alert>
      </>}

      { aliasData?.is_enrolled && getValues('refund_first_name') && !getValues('refund_last_name') && <>
        <Alert borderRadius="md" status="warning" mb="4" alignItems="start">
          <AlertIcon boxSize='22px' />
          <Text fontSize={15}>{ t('steps.refund_zelle.non_individual_message') }</Text>
        </Alert>
      </>}

      { aliasData && !aliasData?.is_enrolled && <>
        <Alert borderRadius="md" status="warning" mb="4" alignItems="start">
          <AlertIcon boxSize='22px' />
          <Text fontSize={15}>
            { t('steps.refund_zelle.not_enrolled_message', {
              alias: aliasData.alias
            })}
          </Text>
        </Alert>
      </>}

      { hasAliasError && <>
        <Alert borderRadius="md" status="error" mb="4" alignItems="start">
          <AlertIcon boxSize='22px' />
          <Text fontSize={15}>{ t('steps.refund_zelle.failed_message') }</Text>
        </Alert>
      </>}

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())} disabled={isSubmitting}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || isCheckingAlias || !isValid }
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundZelleDetailsStep;
