import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input, InputGroup,
  InputLeftElement, Text
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';
import { usePageData } from '../../../../lib/hooks/PageDataContext';

const RefundAmountStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack, values } = useFunnel();
  const { owner } = usePageData();

  const refundMaxAmount = owner.settings['refunds_max-amount'] || 20;

  const schema = yup.object({
    refund_amount: yup.number()
      .transform(val => val || null)
      .nullable()
      .min(0.01, t('fields.refund_amount.validations.min', { num: 0.01 }))
      .max(refundMaxAmount, t('fields.refund_amount.validations.max', { num: refundMaxAmount }))
      .required(t('fields.refund_amount.validations.required')),
  });

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: stepValues({ refund_amount: values.refund_amount })
  });

  const [onSubmit] = useThrottle((data: any) => {
    data.refund_requested = true;
    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="refund_amount" isInvalid={!!errors.refund_amount}>
        <FormLabel variant="heavy">{ t('fields.refund_amount.label') }</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Text color="gray.400">$</Text>}
          />
          <Input
            type="number"
            step=".01"
            defaultValue={getValues('refund_amount')}
            {...register('refund_amount')}
          />
        </InputGroup>

        { errors.refund_amount ?
          <FormErrorMessage>{ errors.refund_amount.message }</FormErrorMessage> :
          <FormHelperText>{ t('fields.refund_amount.aside') }</FormHelperText>
        }
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundAmountStep;
