import * as React from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export function useSize<T extends HTMLElement | null>(
  target: T | React.RefObject<T>,
) {
  const [size, setSize] = React.useState();

  // Where the magic happens
  useResizeObserver(target, (entry) => {
    setSize(entry.target.getBoundingClientRect());
  });

  return size;
}