import { Button, Flex, Heading, Text, Link as A } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const PayRangeDetailsStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward } = useFunnel();

  const { handleSubmit, formState: { isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    defaultValues: stepValues(),
  });

  const [onSubmit] = useThrottle(() => {
    goForward();
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="lg" mb="4">{ t('steps.refund_payrange_details.title') }</Heading>
      <Text>{ t('steps.refund_payrange_details.text') }</Text>

      <br />

      <A href="https://support.payrange.com/hc/en-us/articles/6107023099277-Requesting-a-refund" isExternal color="brand.500">
        { t('steps.refund_payrange_details.how_to_find_link_text') }
      </A>

      <Flex justifyContent="right" mt="6">
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default PayRangeDetailsStep;
