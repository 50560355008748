import SelectorStep from '../form/steps/SelectorStep';
import RateStep from '../form/steps/RateStep';
import FeedbackStep from '../form/steps/FeedbackStep';
import RequestTypeStep from '../form/steps/RequestTypeStep';
import MachineTypeStep from '../form/steps/MachineTypeStep';
import DescriptionStep from '../form/steps/DescriptionStep';
import RequestARefundStep from '../form/steps/refund/RequestARefundStep';
import RequestHelpStep from '../form/steps/RequestHelpStep';
import MachineIDStep from '../form/steps/MachineIDStep';
import PhotoStep from '../form/steps/PhotoStep';
import FeedbackPhotoStep from '../form/steps/FeedbackPhotoStep';
import RefundAmountStep from '../form/steps/refund/RefundAmountStep';
import RefundPaymentMethodStep from '../form/steps/refund/RefundPaymentMethodStep';
import RefundPaymentDateStep from '../form/steps/refund/RefundPaymentDateStep';
import ApplePayDetailsStep from '../form/steps/additional_details/ApplePayDetailsStep';
import AppleCardDetailsStep from '../form/steps/additional_details/AppleCardDetailsStep';
import CardDetailsStep from '../form/steps/additional_details/CardDetailsStep';
import GooglePayDetailsStep from '../form/steps/additional_details/GooglePayDetailsStep';
import PayRangeDetailsStep from '../form/steps/additional_details/PayRangeDetailsStep';
import RefundMethodStep from '../form/steps/refund/RefundMethodStep';
import RefundPaypalDetailsStep from '../form/steps/refund/RefundPaypalDetailsStep';
import RefundVenmoDetailsStep from '../form/steps/refund/RefundVenmoDetailsStep';
import RefundZelleDetailsStep from '../form/steps/refund/RefundZelleDetailsStep';
import RefundVendorCardDetailsStep from '../form/steps/refund/RefundVendorCardDetailsStep';
import RefundDetailsStep from '../form/steps/refund/RefundDetailsStep';
import ContactDetailsStep from '../form/steps/ContactDetailsStep';
import SuccessStep from '../form/steps/SuccessStep';
import AuthHoldInfoStep from '../form/steps/AuthHoldInfoStep';
import ContinueRefundStep from '../form/steps/ContinueRefundStep';
import RefundInteracDetailsStep from '../form/steps/refund/RefundInteracDetailsStep';
import RefundInteracQuestionStep from '../form/steps/refund/RefundInteracQuestionStep';
import RefundInteracAnswerStep from '../form/steps/refund/RefundInteracAnswerStep';

export const STEPS = {
  selector: SelectorStep,
  continue_refund: ContinueRefundStep,
  rate: RateStep,
  feedback: FeedbackStep,
  request_type: RequestTypeStep,
  machine_type: MachineTypeStep,
  description: DescriptionStep,
  help_request: RequestHelpStep,
  refund_request: RequestARefundStep,
  machineID: MachineIDStep,
  tagID: MachineIDStep,
  photo: PhotoStep,
  feedback_photo: FeedbackPhotoStep,
  refund_amount: RefundAmountStep,
  refund_payment_method: RefundPaymentMethodStep,
  refund_payment_date: RefundPaymentDateStep,
  refund_apple_pay_details: ApplePayDetailsStep,
  refund_apple_card_details: AppleCardDetailsStep,
  refund_google_pay_details: GooglePayDetailsStep,
  refund_payrange_details: PayRangeDetailsStep,
  refund_card_details: CardDetailsStep,
  refund_method: RefundMethodStep,
  refund_paypal: RefundPaypalDetailsStep,
  refund_venmo: RefundVenmoDetailsStep,
  refund_zelle: RefundZelleDetailsStep,
  refund_details: RefundDetailsStep,
  refund_vendor_card: RefundVendorCardDetailsStep,
  refund_interac: RefundInteracDetailsStep,
  refund_interac_question: RefundInteracQuestionStep,
  refund_interac_answer: RefundInteracAnswerStep,
  contact: ContactDetailsStep,
  success: SuccessStep,
  auth_hold_info: AuthHoldInfoStep,
};
