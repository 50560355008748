import { forwardRef, useState } from 'react';
import { Input, InputProps, useMergeRefs } from '@chakra-ui/react';
import { usePlacesWidget } from 'react-google-autocomplete';

interface Props extends InputProps {
  country?: string;
}

const AddressAutoComplete = forwardRef<HTMLInputElement, Props>(({country, defaultValue, value, onChange, onBlur, ...props}, ref) => {
  const [inputValue, setValue] = useState<string>(defaultValue?.['formatted_address'] ?? '');

  const { ref: addressRef } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (selected) => {
      if (onChange && selected?.formatted_address) {
        onChange({ target: { value: selected, name: props?.name }} as any);
        setValue(selected?.formatted_address ?? '');
      }
    },
    options: {
      fields: ['business_status', 'name', 'address_components', 'geometry.location', 'place_id', 'formatted_address'],
      types: ['establishment', 'geocode'],
      componentRestrictions: { country: ['us', 'ca'] },
    },
  });

  const mergedRefs = useMergeRefs(ref, addressRef);

  return <>
    <Input
      type="text"
      value={inputValue}
      onChange={(event) => setValue(event.target.value)}
      ref={mergedRefs}
      {...props}
    />
  </>;
});

AddressAutoComplete.displayName = 'AddressAutoComplete';

export default AddressAutoComplete;
