import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, Divider, Flex, useDisclosure, VStack, CircularProgress, Text, FormControl, FormLabel, Box, Accordion, AccordionButton, AccordionItem, AccordionPanel, Spinner, useToast, Alert, AlertIcon, Center } from '@chakra-ui/react';
import AddressAutoComplete from 'components/form/controls/AddressAutoComplete';
import axios from 'lib/api/axios';
import { Tag, usePageData } from 'lib/hooks/PageDataContext';
import { useState, ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { useGeolocation, useDidUpdate } from 'rooks';
import { useI18n } from '../../lib/hooks/I18n';

type Props = {
  tag: Tag;
  onTagUpdate: (tag: Tag) => void;
};

const TagNearby = ({ tag, onTagUpdate }: Props) => {
  const { t } = useI18n();
  const { owner } = usePageData();
  const toast = useToast();
  const { isOpen: isShareOpen, onToggle: onShareToggle } = useDisclosure();
  const { isOpen: isSearchOpen, onToggle: onSearchToggle, onClose: onSearchClose } = useDisclosure();

  const [address, setAddress] = useState<string>(tag.address.formatted_address);
  const [location, setLocation] = useState<{lat: number, lng: number}>({
    lat: tag.address.geometry.location.lat,
    lng: tag.address.geometry.location.lng
  });
  const [gpsEnabled, setGpsEnabled] = useState(false);
  const gps = useGeolocation({ when: gpsEnabled });
  const geocoder = new google.maps.Geocoder();

  useDidUpdate(async () => {
    if (gps?.lat && gps?.lng) {
      setLocation({ lat: gps.lat, lng: gps.lng });

      try {
        const { results } = await geocoder.geocode({ location: {lat: gps.lat, lng: gps.lng}});
        setAddress(results[0].formatted_address);
        closeSearchPanels();
      } catch(e) {
        console.error(e);
      } 
    } else if (gps?.isError) {
      toast({
        title: t('components.tag_nearby.toast.title'),
        description: t('components.tag_nearby.toast.description'),
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top',
      });
    }

    setGpsEnabled(false);
  }, [gps]);

  const handleAddressSearch =  (event: ChangeEvent<any>) => {
    if (event.target?.value?.geometry) {
      const location = event.target.value.geometry.location;
      setLocation({ lat: location.lat(), lng: location.lng() });
      setAddress(event.target.value.formatted_address);
      closeSearchPanels();
    }
  };

  const closeSearchPanels = () => {
    onShareToggle();
    onSearchClose();
  };

  const reset = () => {
    setGpsEnabled(false);
    closeSearchPanels();
  };
  
  const { isLoading, data } = useQuery(['query-nearby-tag', location], async () => {
    return await axios.get<Tag[]>(`/api/owner/${owner.company_url_slug}/tags/nearby?lat=${location.lat}&lng=${location.lng}&tag_id=${tag.id}`);
  });

  return (
    <>
      <VStack
        spacing="0"
        mb="6"
        bgColor="brand.100"
        borderRadius="2xl"
        overflow="hidden"
        alignItems="left"
        divider={<Divider borderColor="brand.600" variant="dashed" />}
      >
        <Flex alignItems="center" px="5" py="3">
          <Text fontSize="sm" mr="4">{ t('components.tag_nearby.title') }</Text>
          <Button
            variant={ isShareOpen ? 'solid' : 'outline' }
            size="sm"
            onClick={isShareOpen ? reset : onShareToggle}
          >{ isShareOpen ? t('common.buttons.cancel') : t('components.tag_nearby.buttons.update') }</Button>
        </Flex>

        { !isShareOpen && address && <Box px="5" py="2">
            <Text fontSize="sm"><strong>{ t('components.tag_nearby.label') }:</strong> { t('common.words.near') } {address}</Text>
          </Box>
        }
        
        { isShareOpen &&
          <Box 
            px="5"
            py="3"
            bgColor="gray.200"
          >
            <Text fontSize="sm">
              { t('components.tag_nearby.share_text') }
            </Text>
            <Flex justifyContent="space-between" mt="4">
              <Button
                isLoading={gpsEnabled}
                variant="solid"
                onClick={() => setGpsEnabled(true)}
                spinner={<Spinner size="sm" color="white" />}
              >
                { t('components.tag_nearby.buttons.share') }
              </Button>
              <Button variant="outline" onClick={onSearchToggle}>{ t('components.tag_nearby.buttons.search') }</Button>
            </Flex>
          </Box>
        } 

        { isSearchOpen &&
          <Box 
            px="5"
            py="3"
            bgColor="gray.200"
          >
            <FormControl id="address_search" mb="0">
              <FormLabel>{ t('components.tag_nearby.control.label') }</FormLabel>
              <AddressAutoComplete country={owner.country_code} placeholder="" onChange={handleAddressSearch} autoFocus={true} />
            </FormControl>
          </Box>
        }
      </VStack>

      { isLoading &&
        <Center>
          <CircularProgress size="10" isIndeterminate color="brand.600" mr="4" />
          <Text fontSize="md" color="gray.700">{ t('components.tag_nearby.states.searching_text') }</Text>
        </Center>
      }

      { !isLoading && !data?.data.length &&
        <Alert status="warning">
          <AlertIcon /> <Text fontSize="sm">{ t('components.tag_nearby.states.no_results_text') }</Text>
        </Alert>
      }

      <Accordion allowToggle allowMultiple>
        { !isLoading && data?.data.map((item, index) => 
          <AccordionItem key={index}>
          {({ isExpanded }) => (
            // @ts-ignore
            <Box bg={ isExpanded ? 'brand.100' : null }>
              <h2>
                <AccordionButton>{item.name} <Text fontSize="xs" ml="2">(ID: {item.identifier})</Text></AccordionButton>
              </h2>
              <AccordionPanel>
                <Flex>
                  <Text fontSize="xs" flex={1}>{item.address.value}.</Text>
                  <Button
                    ml="3"
                    variant="solid"
                    size="sm"
                    rightIcon={<ArrowForwardIcon boxSize={5} />}
                    onClick={() => onTagUpdate(item)}
                  >Use</Button>
                </Flex>
              </AccordionPanel>
            </Box>
          )}
          </AccordionItem>
        )}
      </Accordion>
    </>
  );
};

export default TagNearby;
