import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Text,
  Heading,
  Input, FormLabel
} from '@chakra-ui/react';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useThrottle } from 'rooks';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { usePageData } from 'lib/hooks/PageDataContext';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const RefundInteracDetails = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();
  const { owner } = usePageData();
  const [ interacContactMethod, setInteracContactMethod ] = useState(stepValues()?.interac_contact_method || null);

  const schema = yup.object().shape({
    interac_contact_method: yup.string(),
    refund_email: yup.string().nullable().when('interac_contact_method', {
      is: (method) => !method || method === 'email',
      then: yup.string().nullable().required(t('common.validations.required')).email(t('common.validations.email'))
    }),
    refund_phone: yup.string().nullable().when('interac_contact_method', {
      is: (method) => !method || method === 'mobile',
      then: yup.string().nullable().required(t('common.validations.required')).phone(owner.country_code, t('common.validations.phone'))
    }),
  });

  const { register, getValues, setValue, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle(async (data: any) => {
    if (data.interac_contact_method === 'mobile') {
      data.refund_email = null;
    } else {
      data.refund_phone = null;
    }

    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="2">{ t('steps.refund_interac_details.title') }</Heading>
      <Text mb="4">{ t('steps.refund_interac_details.text') }</Text>

      <FormControl id="interac_contact_method" isInvalid={!!errors.interac_contact_method}>
        <FormLabel>{ t('fields.interac_contact_method.label') }</FormLabel>

        <ButtonGroup colorScheme={!getValues('interac_contact_method') && isSubmitted ? 'red' : 'brand'}>
          <Button variant={ interacContactMethod === 'mobile' ? 'solid' : 'border'}
                  onClick={() => {
                    setValue('interac_contact_method', 'mobile');
                    setInteracContactMethod('mobile');
                  }}>
            { t('fields.refund_phone.label') }
          </Button>
          <Button variant={ interacContactMethod === 'email' ? 'solid' : 'border'}
                  onClick={() => {
                    setValue('interac_contact_method', 'email');
                    setInteracContactMethod('email');
                  }}>
            { t('fields.email.label') }
          </Button>
        </ButtonGroup>
      </FormControl>

      { interacContactMethod === 'mobile' && <>
        <FormControl id="refund_phone" isInvalid={!!errors.refund_phone}>
          <Input
            type="tel"
            maxLength={50}
            placeholder={ t('fields.refund_phone.placeholder') }
            defaultValue={getValues('refund_phone')}
            {...register('refund_phone')}
          />

          <FormErrorMessage>{ errors.refund_phone?.message }</FormErrorMessage>
          <FormHelperText>{ t('fields.refund_phone.aside') }</FormHelperText>
        </FormControl>
      </>}

      { interacContactMethod === 'email' && <>
        <FormControl id="refund_email" isInvalid={!!errors.refund_email}>
          <Input
            type="email"
            maxLength={50}
            placeholder={ t('fields.refund_email.placeholder') }
            defaultValue={getValues('refund_email')}
            {...register('refund_email')}
          />
          <FormErrorMessage>{ errors.refund_email?.message }</FormErrorMessage>
        </FormControl>
      </>}

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid }
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundInteracDetails;
