import { FunnelSteps } from './Funnel';
import { HelpFunnelSteps } from './help-funnel-steps';

const [, ...helpSteps] = HelpFunnelSteps;

export const FeedbackFunnelSteps = [
  {
    name: 'selector',
    next: [
      { target: 'help_request' }
    ]
  },
  {
    name: 'help_request',
    next: [
      {
        target: 'tagID',
        cond: (data, values) => {
          return values.type === 'help' && !data.token?.tag && !data.requestLink?.tag
        }
      },
      { target: 'request_type', cond: (_, values) => values.type === 'help'},
      { target: 'rate' }
    ]
  },
  {
    name: 'tagID',
    next: [
      { target: 'request_type' }
    ]
  },
  {
    name: 'rate',
    next: [
      { target: 'feedback' }
    ]
  },
  {
    name: 'feedback',
    next: [
      { target: 'feedback_photo' }
    ]
  },
  {
    name: 'feedback_photo',
    next: [
      { target: 'contact' },
    ]
  },
  ...helpSteps,
  {
    name: 'contact',
    submit: true,
    next: [
      { target: 'success' },
    ]
  },
  {
    name: 'success',
    next: []
  }
] as FunnelSteps;
