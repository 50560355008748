import {
  Center,
  FormLabel,
  IconButton,
  Input,
  Image,
  InputProps,
  useFormControlContext,
  useStyleConfig, useMergeRefs
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { ChangeEvent, forwardRef, MouseEvent, useEffect, useRef, useState } from 'react';
import imageCompression from 'browser-image-compression';

const ImageSelector = forwardRef<HTMLInputElement, InputProps>(({ onChange, defaultValue, ...props }, ref) => {
  const [previewValue, setPreviewValue] = useState<File | Blob | string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const previewRef = useRef<HTMLImageElement>(null);
  const field = useFormControlContext();
  const styles = useStyleConfig('Input');

  useEffect(() => {
    const file = !defaultValue ? null : defaultValue instanceof FileList ? defaultValue.item(0) : defaultValue;

    if (file) {
      const reader = new FileReader();

      setPreviewValue(file);

      reader.onload = (e) => {
        if (previewRef.current && e.target) {
          previewRef.current.src = String(e.target.result);
        }
      }

      reader.readAsDataURL(file);
    }
  }, [defaultValue])

  const handleCompressionAndPreview = async (e: ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.item(0);

    if (file) {
      try {
        console.log(`Image size before ${file.size / 1024 / 1024} MB`);

        const compressedFile = await imageCompression(file, {
          maxSizeMB: 3,
          maxWidthOrHeight: 1440,
          useWebWorker: true,
        });

        file = compressedFile;

        console.log(`Image size after ${compressedFile.size / 1024 / 1024} MB`);
      } catch (error) {
        console.error(error);
      }

      setPreviewValue(file);

      if (onChange) {
        onChange({target: {value: file, name: props.name}} as ChangeEvent<HTMLInputElement>);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (previewRef.current && e.target) {
          previewRef.current.src = String(e.target.result);
        }
      }
    }
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setPreviewValue('');

    if (inputRef.current) {
      inputRef.current.value = '';

      if (onChange) {
        onChange({target: {value: null as unknown, name: props.name}} as ChangeEvent<HTMLInputElement>);
      }
    }

    if (previewRef.current) {
      previewRef.current.src = '';
    }
  };

  return (
    <>
      <Input
        type="file"
        hidden={true}
        multiple={false}
        accept="image/*"
        ref={useMergeRefs(ref, inputRef)}
        onChange={(e) => {
          if (e.target.files && e.target.files.length) {
            handleCompressionAndPreview(e);
          }
        }}
        { ...props }
      />
      <FormLabel m="0">
        <Center
          h={ !previewValue ? '300' : 'auto' }
          minH="150"
          backgroundColor="gray.100"
          borderRadius="2xl"
          boxShadow="xl"
          position="relative"
          overflow="hidden"
          border="2px solid"
          borderColor="transparent"
          aria-invalid={field.isInvalid}
          // @ts-ignore
          __css={{ _invalid: styles.field._invalid }}
        >
          {!previewValue &&
            <IconButton
              as="span"
              height="70px" width="70px"
              borderRadius="full"
              aria-label="Add photo"
              icon={<AddIcon boxSize="6"/>}
            />
          }

          { previewValue && <>
              <IconButton
                as="span"
                height="44px" width="44px"
                borderRadius="full"
                colorScheme="red"
                aria-label="Remove photo"
                position="absolute"
                top="4" right="4"
                icon={<MinusIcon boxSize="6" />}
                onClick={handleRemove}
              />

              <Image ref={previewRef} alt="" />
            </>
          }
        </Center>
      </FormLabel>
    </>
  );
});

ImageSelector.displayName = 'ImageSelector';

export default ImageSelector;
