import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/react';
import { ArrowForwardIcon, WarningTwoIcon } from '@chakra-ui/icons';
import ImageSelector from '../controls/ImageSelector';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { usePageData } from 'lib/hooks/PageDataContext';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';

const PhotoStep = () => {
  const { t, locale } = useI18n();
  const { stepValues, values, goForward, goBack } = useFunnel();
  const { owner, prefill } = usePageData();

  const refund_requested = values.refund_requested;

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
  const schema = yup.object({
    photo: yup.mixed()
      .transform(value => !value ? null : value instanceof FileList ? value.item(0) : value)
      .when('$required', (required, schema) => required ? schema.required(t('fields.photo.validations.required')) : schema)
      .test('fileFormat', t('fields.photo.validations.file_format'), value => !value || SUPPORTED_FORMATS.includes(value?.type))
  });

  const overridePhotoRequirement = prefill.tag?.meta && 'photo_requirement' in prefill.tag.meta;
  const isPhotoRequired = overridePhotoRequirement ?
    prefill.tag?.meta.photo_requirement :
    owner.require_image_flg || refund_requested && owner?.require_photo_in_refund_flg;

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: stepValues(),
    context: {
      required: isPhotoRequired,
    }
  });

  const [onSubmit] = useThrottle(async (data: any) => {
    goForward(data);
  }, 1000);

  const formatWithOptionalQuestionMark = (value) => {
    if (!isPhotoRequired) {
      if (locale === 'es') {
        return `¿${value}?`;
      }

      return `${value}?`;
    }

    return value;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="photo" isInvalid={!!errors.photo}>
        <FormLabel variant="heavy">
          { formatWithOptionalQuestionMark(t('fields.photo.label')) }
        </FormLabel>
        <Text fontSize={13} color="gray" mt={-2} mb={3}><WarningTwoIcon /> { t('fields.photo.warning') }</Text>
        <ImageSelector
          defaultValue={getValues('photo')}
          {...register('photo')}
        />
        <FormErrorMessage>{errors.photo?.message}</FormErrorMessage>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default PhotoStep;
