import { FormControl, StackDivider, VStack, RadioGroup, Radio, Flex, Button, FormLabel } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { usePageData } from 'lib/hooks/PageDataContext';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';

const MachineTypeStep = () => {
  const { t, locale } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();
  const { owner } = usePageData();

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle((data: any) => {
    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="machine_type" isInvalid={!!errors.machine_type}>
        <FormLabel variant="heavy">{ t('fields.machine_type.label') }</FormLabel>
        <RadioGroup defaultValue={getValues('machine_type')}>
          <VStack
            spacing="0"
            mb="4"
            overflow="hidden"
            backgroundColor="gray.100"
            borderRadius="2xl"
            boxShadow="xl"
            alignItems="left"
            divider={<StackDivider borderColor="gray.300" />}
          >
            {
              owner.machine_types.map((option, index ) =>
                <Radio
                  key={index}
                  value={String(option.id)}
                  px="5" py="4"
                  {...register('machine_type', { required: true })}
                >{option.translations?.[locale] ?? option.type}</Radio>
              )
            }
          </VStack>
        </RadioGroup>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default MachineTypeStep;
