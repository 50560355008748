import {
  Alert, AlertDescription, Box,
  Button,
  Link as A,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText, Heading, IconButton,
  Input, InputGroup, Text, useDisclosure
} from '@chakra-ui/react';
import { ArrowForwardIcon, CloseIcon, InfoIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const AppleCardDetailsStep = () => {
  const { t } = useI18n();
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
  const { stepValues, goForward, goBack } = useFunnel();

  const schema = yup.object({
    last_four_digits: yup.string()
      .transform(val => val || null)
      .nullable()
      .test('len', t('fields.last_four_digits.validations.length'), val => `${val}`.length === 4)
      .matches(/^[0-9]+$/, t('fields.last_four_digits.validations.required'))
      .required(t('fields.last_four_digits.validations.length')),
  });

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: stepValues()?.payment_method_attributes || stepValues(),
  });

  const [onSubmit] = useThrottle((data: any) => {
    goForward({
      payment_method_attributes: data,
    });
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="lg" mb="4">{ t('steps.refund_apple_card_details.title') }</Heading>
      <Text fontSize={13} mt={-2} mb={6}>{ t('steps.refund_apple_card_details.text') }</Text>

      <FormControl id="last_four_digits" isInvalid={!!errors.last_four_digits}>
        <InputGroup w={170}>
          <Input
            type="text"
            maxLength="4"
            defaultValue={getValues('last_four_digits')}
            {...register('last_four_digits')}
          />
        </InputGroup>

        { errors.last_four_digits ?
          <FormErrorMessage>{ errors.last_four_digits.message }</FormErrorMessage> :
          <FormHelperText>{ t('fields.last_four_digits.apple_card_aside') }</FormHelperText>
        }
      </FormControl>

      <Button leftIcon={<InfoIcon style={{ marginRight: 1 }} boxSize="5" />}
              color="#1776BF"
              variant="link"
              textDecoration="none !important"
              fontSize="sm"
              mb={4}
              whiteSpace="normal"
              textAlign="left"
              onClick={onOpen}
      >
        { t('steps.refund_apple_card_details.how_to_find_link_text') }
      </Button>

      {isOpen &&
        <Alert borderRadius="2xl">
          <Box mr={1}>
            <AlertDescription>
              { t('steps.refund_apple_card_details.how_to_find_text') }
            </AlertDescription>
          </Box>

          <IconButton
            alignSelf="flex-start"
            position="relative"
            bg="black"
            colorScheme="blackAlpha"
            size="sm"
            height="8"
            icon={<CloseIcon boxSize="3"/>}
            onClick={onClose}
          />
        </Alert>
      }

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default AppleCardDetailsStep;
