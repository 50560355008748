import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Spacer } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useThrottle } from 'rooks';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const RefundPaypalDetailsStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack, isSubmitting } = useFunnel();

  const schema = yup.object().shape({
    refund_email: yup.string().nullable().email(t('common.validations.email')).required(t('common.validations.required')),
  });

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle(async (data: any) => {
    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="4">{ t('steps.refund_paypal.title') }</Heading>

      <FormControl id="refund_email" isInvalid={!!errors.refund_email}>
        <FormLabel>
          <Flex>{ t('fields.refund_email.label') }<Spacer /><FormErrorMessage>{ errors.refund_email?.message }</FormErrorMessage></Flex>
        </FormLabel>
        <Input
          type="email"
          maxLength={50}
          defaultValue={getValues('refund_email')}
          {...register('refund_email')}
        />
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())} disabled={isSubmitting}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundPaypalDetailsStep;
