import { CloseIcon } from '@chakra-ui/icons';
import { FormControl, FormHelperText, Flex, Button, Box, useDisclosure, Modal, ModalContent, ModalOverlay, Container, IconButton, Heading, Text } from '@chakra-ui/react';
import { AsyncSelect, chakraComponents, GroupBase, OptionProps } from 'chakra-react-select';
import TagNearby from './TagNearby';
import axios from 'lib/api/axios';
import { Tag, usePageData } from 'lib/hooks/PageDataContext';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useI18n } from '../../lib/hooks/I18n';
import debounce from '../../lib/debounce';

type Props = {
  tag: Tag;
  onTagUpdate: (tag: Tag) => void;
};

const TagSearch = ({ tag, onTagUpdate }: Props) => {
  const { t } = useI18n();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { owner } = usePageData();

  const schema = yup.object().shape({
    tagSearch: yup.mixed().required(),
  });

  const { register, setValue, getValues, formState: { isValid }} = useForm<any>({
    resolver: yupResolver(schema),
  });

  const findTagsByTerm = async (inputValue: string) => {
    const { data } = await axios.get<Tag[]>(`/api/owner/${owner.company_url_slug}/tags/search?identifier=${inputValue}`);

    return data?.map((item) => ({value: item, label: item.identifier}));
  };

  const dropdownComponents = {
    Option: ({ children, ...props }: OptionProps<{ value: Tag, label: string }, true, GroupBase<{ value: Tag, label: string }>>) => (
      <chakraComponents.Option {...props}>
        {children}
        <Text fontSize="xs" ml="2">({props.data.value.name})</Text>
      </chakraComponents.Option>
    ),
    DropdownIndicator: () => null,
  };

  const handleTagUpdate = (tag: Tag) => {
    onTagUpdate(tag);
    onClose();
  };

  return (
    <>
      <Box
        px="5"
        py="4"
        bgColor="gray.200"
        borderBottomRadius="2xl"
      >
        <FormControl id="tagSearch">
          <AsyncSelect
            { ...register('tagSearch') }
            components={dropdownComponents}
            openMenuOnFocus={false}
            openMenuOnClick={false}
            isClearable={true}
            cacheOptions
            loadOptions={debounce(findTagsByTerm, 300)}
            onChange={(val: any) => setValue('tagSearch', val?.value, { shouldValidate: true })}
            autoFocus={true}
          />

          <FormHelperText>{ t('components.tag_search.control.aside') }</FormHelperText>
        </FormControl>

        <Flex justifyContent="space-between">
          <Button variant="solid" colorScheme="brand" disabled={!isValid} onClick={() => onTagUpdate(getValues('tagSearch'))}>
            { t('components.tag_search.buttons.update') }
          </Button>
          {/*{*/}
          {/*  tag.address.geometry.location.lat !== 0 && tag.address.geometry.location.lng !== 0 && <Button variant="outline" onClick={onOpen}>*/}
          {/*    { t('components.tag_search.buttons.nearby') }*/}
          {/*  </Button>*/}
          {/*}*/}
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <Container maxW="container.sm" py="8">
            <Flex alignItems="baseline" mb="4" justifyContent="space-between">
              <Heading size="md" fontWeight="bold">{ t('components.tag_search.modal.title') }</Heading>
              <IconButton
                aria-label="Close Modal"
                bg="black"
                colorScheme="blackAlpha"
                size="sm"
                height="8"
                icon={<CloseIcon boxSize="3" />}
                onClick={onClose}
              />
            </Flex>
            <TagNearby tag={tag} onTagUpdate={handleTagUpdate} />
          </Container>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TagSearch;
