import { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  FormControl, FormErrorMessage, FormHelperText,
  FormLabel,
  Heading,
  Input,
  Spacer, Switch
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import AddressAutoComplete from '../../controls/AddressAutoComplete';
import { useThrottle } from 'rooks';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { usePageData } from '../../../../lib/hooks/PageDataContext';
import { postcodeValidator } from 'postcode-validator';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const RefundDetailsStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack, isSubmitting } = useFunnel();
  const { owner } = usePageData();
  const [manualAddress, setManualAddress] = useState<boolean>(false);

  const stateField = owner.country_code === 'CA' ? 'refund_province' : 'refund_state';
  const zipCodeField = owner.country_code === 'CA' ? 'refund_postal_code' : 'refund_zip_code';
  const zipCodeValidationField = owner.country_code === 'CA' ? 'postal_code' : 'zip_code';

  const schema = yup.object().shape({
    refund_name: yup.string().required(t('common.validations.required')),
    refund_address: yup.object().nullable().when('$manualAddress', {
      is: false, then: (schema) => schema.required(t('common.validations.address'))
    }),
    refund_address_line_1: yup.string().when('$manualAddress', {
      is: true, then: (schema) => schema.required(t('common.validations.required'))
    }),
    refund_address_line_2: yup.string().when('$manualAddress', {
      is: true, then: (schema) => schema.optional()
    }),
    refund_city: yup.string().when('$manualAddress', {
      is: true, then: (schema) => schema.required(t('common.validations.required'))
    }),
    refund_state: yup.string().when('$manualAddress', {
      is: true, then: (schema) => schema.required(t('common.validations.required'))
    }),
    refund_zip_code: yup.string().when('$manualAddress', {
      is: true,
      then: (schema) => schema
        .test('zip_code_valid', t(`common.validations.${zipCodeValidationField}`), (value) => postcodeValidator(value, 'US') || postcodeValidator(value, 'CA'))
        .required(t('common.validations.required'))
    }),
  });

  const { register, getValues, handleSubmit, trigger, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: stepValues(),
    context: {
      manualAddress
    },
  });

  useEffect(() => {
    if (isDirty && !manualAddress) {
      trigger();
    }
  }, [manualAddress]);

  const [onSubmit] = useThrottle(async (data: any) => {
    if (isValid) {
      data.refund_address = !manualAddress ? data.refund_address : null;
      goForward(data);
    }
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="4">{ t('steps.refund_details.title') }</Heading>

      <FormControl id="refund_name" isInvalid={!!errors.refund_name}>
        <FormLabel>
          <Flex>{ t('fields.refund_name.label') }<Spacer /><FormErrorMessage>{ errors.refund_name?.message }</FormErrorMessage></Flex>
        </FormLabel>
        <Input
          type="text"
          maxLength={75}
          placeholder={ t('fields.refund_name.placeholder') }
          defaultValue={getValues('refund_name')}
          {...register('refund_name')}
        />
      </FormControl>

      <div hidden={manualAddress}>
        <FormControl id="refund_address" isInvalid={!!errors.refund_address} isDisabled={manualAddress}>
          <FormLabel>
              <Flex>{ t('fields.refund_address.label') }<Spacer/><FormErrorMessage>{errors.refund_address?.message}</FormErrorMessage></Flex>
          </FormLabel>

          <AddressAutoComplete
            country={owner.country_code}
            placeholder={ t('fields.refund_address.placeholder') }
            defaultValue={getValues('refund_address')}
            {...register('refund_address')}  />

          <FormHelperText>{ t('fields.refund_address.aside') }</FormHelperText>
        </FormControl>
      </div>

      <div hidden={!manualAddress}>
        <FormControl id="refund_address_line_1" isInvalid={!!errors.refund_address_line_1} isDisabled={!manualAddress}>
          <FormLabel>
            <Flex>{ t('fields.refund_address_line_1.label') }<Spacer /><FormErrorMessage>{ errors.refund_address_line_1?.message }</FormErrorMessage></Flex>
          </FormLabel>
          <Input
            type="text"
            maxLength={100}
            defaultValue={getValues('refund_address_line_1')}
            {...register('refund_address_line_1', { disabled: !manualAddress })}
          />
        </FormControl>

        <FormControl id="refund_address_line_2" isInvalid={!!errors.refund_address_line_2} isDisabled={!manualAddress}>
          <FormLabel>
            <Flex>{ t('fields.refund_address_line_2.label') }<Spacer /><FormErrorMessage>{ errors.refund_address_line_2?.message }</FormErrorMessage></Flex>
          </FormLabel>
          <Input
            type="text"
            maxLength={75}
            defaultValue={getValues('refund_address_line_2')}
            {...register('refund_address_line_2', { disabled: !manualAddress })}
          />
        </FormControl>

        <FormControl id="refund_city" isInvalid={!!errors.refund_city} isDisabled={!manualAddress}>
          <FormLabel>
            <Flex>{ t('fields.refund_city.label') }<Spacer /><FormErrorMessage>{ errors.refund_city?.message }</FormErrorMessage></Flex>
          </FormLabel>
          <Input
            type="text"
            maxLength={50}
            defaultValue={getValues('refund_city')}
            {...register('refund_city', { disabled: !manualAddress })}
          />
        </FormControl>

        <FormControl id="refund_state" isInvalid={!!errors.refund_state} isDisabled={!manualAddress}>
          <FormLabel>
            <Flex>{ t(`fields.${stateField}.label`) }<Spacer /><FormErrorMessage>{ errors.refund_state?.message }</FormErrorMessage></Flex>
          </FormLabel>
          <Input
            type="text"
            maxLength={50}
            defaultValue={getValues('refund_state')}
            {...register('refund_state', { disabled: !manualAddress })}
          />
        </FormControl>

        <FormControl id="refund_zip_code" isInvalid={!!errors.refund_zip_code} isDisabled={!manualAddress}>
          <FormLabel>
            <Flex>{ t(`fields.${zipCodeField}.label`) }<Spacer /><FormErrorMessage>{ errors.refund_zip_code?.message }</FormErrorMessage></Flex>
          </FormLabel>
          <Input
            type="text"
            maxLength={50}
            defaultValue={getValues('refund_zip_code')}
            {...register('refund_zip_code', { disabled: !manualAddress })}
          />
        </FormControl>
      </div>

      <FormControl display="flex" alignItems="center">
        <Switch
          id="manual_address"
          size="lg"
          mr="4"
          colorScheme="brand"
          onChange={(event) => setManualAddress(event.target.checked)}
        />
        <FormLabel htmlFor='manual_address'>{ t('fields.manual_address.label') }</FormLabel>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())} disabled={isSubmitting}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundDetailsStep;
