import {
  Button,
  Flex,
  FormControl,
  FormLabel, Radio,
  RadioGroup, StackDivider,
  VStack
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const RequestARefundStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    defaultValues: stepValues()
  });

  const options = [
    { label: t('fields.refund_requested.options.yes'), value: true },
    { label: t('fields.refund_requested.options.no'), value: false }
  ];

  const [onSubmit] = useThrottle((data: any) => {
    data.refund_requested = data.refund_requested === 'true';

    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="refund_requested" isInvalid={!!errors.refund_requested}>
        <FormLabel variant="heavy" mb="2">{ t('fields.refund_requested.label') }</FormLabel>
        <FormLabel fontSize="sm" mb="4">{ t('fields.refund_requested.text') }</FormLabel>
        <RadioGroup defaultValue={getValues('refund_requested')?.toString()}>
          <VStack
            spacing="0"
            mb="4"
            overflow="hidden"
            backgroundColor="gray.100"
            borderRadius="2xl"
            boxShadow="xl"
            alignItems="left"
            divider={<StackDivider borderColor="gray.300" />}
          >
            {
              options.map((option, index) =>
                <Radio
                  key={index}
                  value={String(option.value)}
                  px="5" py="4"
                  {...register('refund_requested', { required: true })}
                >{option.label}</Radio>
              )
            }
          </VStack>
        </RadioGroup>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RequestARefundStep;
