import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/react';
import { ArrowForwardIcon, WarningTwoIcon } from '@chakra-ui/icons';
import ImageSelector from '../controls/ImageSelector';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';

const FeedbackPhotoStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
  const schema = yup.object({
    photo: yup.mixed()
      .transform(value => !value ? null : value instanceof FileList ? value.item(0) : value)
      .test('fileFormat', t('fields.photo.validations.file_format'), value => !value || SUPPORTED_FORMATS.includes(value?.type))
  });

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: stepValues(),
  });

  const [onSubmit] = useThrottle(async (data: any) => {
    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="photo" isInvalid={!!errors.photo}>
        <FormLabel variant="heavy">{ t('fields.photo.label') }?</FormLabel>
        <Text fontSize={13} color="gray" mt={-2} mb={3}><WarningTwoIcon /> { t('fields.photo.warning') }</Text>
        <ImageSelector
          defaultValue={getValues('photo')}
          {...register('photo')}
        />
        <FormErrorMessage>{errors.photo?.message}</FormErrorMessage>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default FeedbackPhotoStep;
