import {
  Button,
  Flex,
  FormControl, FormErrorMessage, FormHelperText,
  FormLabel, Radio,
  RadioGroup, StackDivider, Text,
  VStack
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm, useWatch } from 'react-hook-form';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const RefundPaymentDateStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();

  const options = [
    { value: 0, label: t('fields.refund_days_since_purchase.options.today') },
    { value: 1, label: t('fields.refund_days_since_purchase.options.yesterday') },
    { value: 2, label: t('fields.refund_days_since_purchase.options.two_days_ago') },
    { value: 3, label: t('fields.refund_days_since_purchase.options.more_than_two_days_ago') },
  ];

  const schema = yup.object({
    refund_days_since_purchase: yup.string().required(t('common.validations.required')),
    purchased_at: yup.string().nullable().when('refund_days_since_purchase', {
      is: (v) =>  v === '3',
      then: (schema) => schema.required(t('common.validations.required')),
    }),
  });

  const { register, trigger, getValues, setValue, control, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: stepValues(),
  });

  const [onSubmit] = useThrottle((data: any) => {
    if (data.refund_days_since_purchase !== '3') {
      data.purchased_at = null;
    } else {
      data.purchased_at = dayjs(data.purchased_at).format();
    }

    goForward(data);
  }, 1000);

  const daysSincePurchase = useWatch({ control, name: 'refund_days_since_purchase' });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="refund_days_since_purchase" isInvalid={!!errors.refund_days_since_purchase}>
        <FormLabel variant="heavy">{ t('fields.refund_days_since_purchase.label') }</FormLabel>
        <Text fontSize={13} color="gray" mt={-2} mb={3}>{ t('fields.refund_days_since_purchase.text') }</Text>
        <RadioGroup defaultValue={getValues('refund_days_since_purchase')}>
          <VStack
            spacing="0"
            mb="4"
            overflow="hidden"
            backgroundColor="gray.100"
            borderRadius="2xl"
            boxShadow="xl"
            alignItems="left"
            divider={<StackDivider borderColor="gray.300" />}
          >
            {
              options.map((option, index) =>
                <Radio
                  key={index}
                  value={String(option.value)}
                  px="5" py="4"
                  {...register('refund_days_since_purchase', { required: true })}
                >{option.label}</Radio>
              )
            }
          </VStack>
        </RadioGroup>
      </FormControl>

      { daysSincePurchase === '3' && <>
        <FormControl id="purchased_at" isInvalid={!!errors.purchased_at}>
          <FormLabel>{ t('fields.purchased_at.label') }</FormLabel>
          <DateInput
            {...register('purchased_at')}
            defaultValue={getValues('purchased_at') ? dayjs(getValues('purchased_at')).toDate() : dayjs().subtract(3, 'days').toDate()}
            name="purchased_at"
            onChange={(v) => {
              setValue('purchased_at', v);
              trigger('purchased_at');
            }}
            error={!!errors.purchased_at}
            maxLevel="month"
            maxDate={dayjs().subtract(3, 'days').toDate()}
            minDate={dayjs().subtract(1, 'month').toDate()}
            clearable
          />

          { errors.purchased_at && <FormErrorMessage>{ errors.purchased_at.message }</FormErrorMessage>}
        </FormControl>
      </>}


      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundPaymentDateStep;
