import { useState } from 'react';
import axios from '../api/axios';
import { usePageData } from './PageDataContext';

export function useCheckZelleAlias() {
  const { owner } = usePageData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ hasError, setHasError ] = useState(false);
  const [ data, setData ] = useState(null);

  const checkAlias = async ({ enrollment_method, alias }) => {
    if (!alias) {
      return;
    }

    try {
      setIsLoading(true);
      const results = await axios.post(`/api/owner/${owner.company_url_slug}/zelle/check_alias`, { enrollment_method, alias });
      setIsLoading(false);
      setHasError(false);
      setData(results.data);

      return results.data;
    } catch (e) {
      setIsLoading(false);
      setHasError(true);
      setData(null);

      console.error(e);
    }
  };

  const reset = () => {
    setIsLoading(false);
    setHasError(false);
    setData(null);
  }

  return {
    isLoading,
    hasError,
    data,
    checkAlias,
    reset
  };
}