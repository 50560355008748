import { createElement, forwardRef } from 'react';
import { motion } from 'framer-motion';
import { useFunnel } from './Funnel';

const FunnelStepAnimation = forwardRef<unknown, any>(({ step }, ref) => {
  const { direction } = useFunnel();

  const width = typeof window !== 'undefined' ? window.innerWidth : 1000;

  const variants = {
    enter: {
      x: direction > 0 ? width : -width,
      opacity: 0,
    },
    center: {
      x: 0,
      opacity: 1,
    },
    exit: {
      x: direction > 0 ? -width : width,
      opacity: 0,
    }
  };

  return <motion.div
    ref={ref}
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    transition={{
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }}
  >
    { createElement(step) }
  </motion.div>;
});

export default FunnelStepAnimation;
