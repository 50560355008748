import { Text, VStack, Link as A, Box, Heading } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useI18n } from '../../lib/hooks/I18n';
import { usePageData } from '../../lib/hooks/PageDataContext';
import { useEffect, useState } from 'react';

const Footer = () => {
  const { query } = useRouter();
  const [ url, setUrl ] = useState<URL>();
  const { owner } = usePageData();
  const { t } = useI18n();

  useEffect(() => {
    setUrl(new URL(window.location.href));
  }, []);

  const URLS = {
    aboutUs: owner.settings['content_url_about-us'] || `/${query.company_slug}/about-us`,
    termsOfUse: owner.settings['content_url_terms-of-use'] || `/${query.company_slug}/terms`,
    privacyPolicy: owner.settings['content_url_privacy-policy'] || `/${query.company_slug}/privacy`,
  };

  return (
    <Box as="footer" mt="10">
      <VStack align="left" mb="8" display="inline-flex">
        <Link href={URLS.termsOfUse} passHref legacyBehavior>
          <A isExternal fontSize="17">
            { t('common.footer.terms_of_use') }
          </A>
        </Link>
        <Link href={URLS.privacyPolicy} passHref legacyBehavior>
          <A isExternal fontSize="17">
            { t('common.footer.privacy_policy') }
          </A>
        </Link>
        <Link href={URLS.aboutUs} passHref legacyBehavior>
          <A isExternal fontSize="17">
          { t('common.footer.about_us') }
          </A>
        </Link>
      </VStack>


      { url && owner.supported_languages.length > 1 && <>
        <Heading fontSize="sm" mb="4" fontWeight="bold">{ t('common.footer.language_options') }</Heading>

        <VStack align="left" mb="8" display="inline-flex">
          { owner.supported_languages.includes('en') && <>
            { url.searchParams.set('lang', 'en') }
            <A href={url.toString()} fontSize="17">English</A>
          </>}
          { owner.supported_languages.includes('fr') && <>
            { url.searchParams.set('lang', 'fr') }
            <A href={url.toString()} fontSize="17">Français</A>
          </>}
          { owner.supported_languages.includes('es') && <>
            { url.searchParams.set('lang', 'es') }
            <A href={url.toString()} fontSize="17">Español</A>
          </>}
        </VStack>
      </> }

      <Text fontSize="xs">
        <A href="https://zippyassist.com" isExternal color="black">
          ZippyAssist&trade; by 6L Inc. { t('common.footer.all_rights') } &copy;
        </A>
      </Text>
    </Box>
  );
};

export default Footer;
