import { useState } from 'react';
import axios from '../api/axios';
import { usePageData } from './PageDataContext';

export function useValidateEmail() {
  const { owner } = usePageData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ hasError, setHasError ] = useState(false);
  const [ data, setData ] = useState(null);

  const validateEmail = async (email) => {
    if (!email) {
      return;
    }

    try {
      setIsLoading(true);
      const results = await axios.post(`/api/owner/${owner.company_url_slug}/validate_email`, { email });
      setIsLoading(false);
      setHasError(false);
      setData(results.data.valid);

      return results.data.valid;
    } catch (e) {
      setIsLoading(false);
      setHasError(true);
      setData(null);

      console.error(e);
    }
  };

  const reset = () => {
    setIsLoading(false);
    setHasError(false);
    setData(null);
  };

  return {
    isLoading,
    hasError,
    data,
    validateEmail,
    reset,
  };
}
