import {
  Button,
  Flex,
  FormControl,
  FormLabel, Radio,
  RadioGroup, StackDivider,
  VStack, Text
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useThrottle } from 'rooks';
import { usePageData } from '../../../../lib/hooks/PageDataContext';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const RefundMethodStep = () => {
  const { t, locale } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();
  const { owner, prefill, refundMethods } = usePageData();

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle((data: any) => {
    data.refund_method_id = Number(data.refund_method);
    data.refund_method = owner.refund_methods.find(i => i.id === data.refund_method_id)?.type;

    goForward(data);
  }, 1000);

  const overrideRefundMethods = prefill.tag?.meta.allowed_refund_methods;
  const allowedRefundMethods = overrideRefundMethods?.length ?
    refundMethods.filter(rf => overrideRefundMethods?.includes(String(rf.value))) :
    refundMethods.filter(rf => rf.is_active);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="refund_method" isInvalid={!!errors.refund_method}>
        <FormLabel variant="heavy">{ t('fields.refund_method.label') }</FormLabel>
        <FormLabel fontSize="sm" mb="4">{ t('fields.refund_method.text') }</FormLabel>

        <RadioGroup defaultValue={String(getValues('refund_method_id'))}>
          <VStack
            spacing="0"
            mb="4"
            overflow="hidden"
            backgroundColor="gray.100"
            borderRadius="2xl"
            boxShadow="xl"
            alignItems="left"
            divider={<StackDivider borderColor="gray.300" />}
          >
            {
              allowedRefundMethods
                .map((option, index) =>
                  <Radio
                    key={index}
                    value={String(option.value)}
                    px="5" py="4"
                    {...register('refund_method', { required: true })}
                  >
                    <Text>{option.translations?.name?.[locale] ?? option.label}</Text>
                    { option.translations?.description?.[locale] && <Text fontSize="sm">{ option.translations?.description[locale] }</Text> }
                  </Radio>
                )
            }
          </VStack>
        </RadioGroup>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundMethodStep;
