import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Textarea } from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';

type Props = ComponentPropsWithoutRef<'textarea'>;

const AutoResizeTextarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  return (
    <Textarea
      as={ResizeTextarea}
      minRows={8}
      resize="none"
      ref={ref}
      {...props}
    />
  );
});

AutoResizeTextarea.displayName = 'AutoResizeTextarea';

export default AutoResizeTextarea;
