import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spacer
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useThrottle } from 'rooks';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';
import { usePageData } from '../../../../lib/hooks/PageDataContext';

const RefundVendorCardDetailsStep = () => {
  const { t, locale } = useI18n();
  const { stepValues, values, goForward, goBack, isSubmitting } = useFunnel();
  const { owner } = usePageData();

  const method = values.refund_method_id ?
    owner.refund_methods.find(i => i.id === values.refund_method_id) :
    owner.payment_methods.find(i => i.id === Number(values.refund_payment_method));

  const maxLength = method?.settings.vendorcard_maxlength ?? 16;
  const methodName = method?.translations?.name?.[locale] ?? method?.name;

  const isCardNumberIdentifier = method?.settings['vendorcard_account-identifier'] === 'CardNumber';
  const isEmailIdentifier = method?.settings['vendorcard_account-identifier'] === 'Email';
  const IsPhoneIdentifier = method?.settings['vendorcard_account-identifier'] === 'PhoneNumber';

  const schema = yup.object().shape({
    oan_card_number: yup.string().when({
      is: () => isCardNumberIdentifier,
      then: yup.string()
        .required(t('common.validations.required'))
        .matches(new RegExp(`^.{${maxLength}}$`), t('common.validations.oan_card_number'))
    }),
    refund_email: yup.string().when({
      is: () => isEmailIdentifier,
      then: yup.string()
        .required(t('common.validations.required'))
        .email(t('common.validations.email'))
    }),
    refund_phone: yup.string().when({
      is: () => IsPhoneIdentifier,
      then: yup.string()
        .required(t('common.validations.required'))
        .phone(owner.country_code, t('common.validations.phone'))
    }),
  });

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle(async (data: any) => {
    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="4">
        { t('steps.refund_vendor_card.title', { name: methodName }) }
      </Heading>

      { isCardNumberIdentifier && <>
        <FormControl id="oan_card_number" isInvalid={!!errors.oan_card_number}>
          <FormLabel>
            <Flex>{ t('fields.refund_oan_card_number.label') }<Spacer /><FormErrorMessage>{ errors.oan_card_number?.message }</FormErrorMessage></Flex>
          </FormLabel>
          <Input
            type="number"
            inputMode="numeric"
            maxLength={maxLength}
            defaultValue={getValues('oan_card_number')}
            {...register('oan_card_number')}
          />
        </FormControl>
      </>}

      { isEmailIdentifier && <>
        <FormControl id="refund_email" isInvalid={!!errors.refund_email}>
          <Input
            type="email"
            maxLength={50}
            placeholder={ t('fields.refund_email.placeholder') }
            defaultValue={getValues('refund_email')}
            {...register('refund_email')}
          />
          <FormErrorMessage>{ errors.refund_email?.message }</FormErrorMessage>
        </FormControl>
      </>}

      { IsPhoneIdentifier && <>
        <FormControl id="refund_phone" isInvalid={!!errors.refund_phone}>
          <Input
            type="tel"
            maxLength={50}
            placeholder={ t('fields.refund_phone.placeholder') }
            defaultValue={getValues('refund_phone')}
            {...register('refund_phone')}
          />
          <FormErrorMessage>{ errors.refund_phone?.message }</FormErrorMessage>
        </FormControl>
      </>}

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())} disabled={isSubmitting}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundVendorCardDetailsStep;
