import { Heading, Button, Flex, Text, Box, Image, Center, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';

const AuthHoldInfoStep = () => {
  const { t } = useI18n();
  const { goForward } = useFunnel();

  return (
    <>
      <Heading fontSize="xl" mb="4" fontWeight="bold">{ t('steps.auth_hold_info.title') }</Heading>

      <Text>{ t('steps.auth_hold_info.text') }</Text>

      <Center mt="2" mb="4">
        <Image src="images/auth-timeframe-24-72.png" />
      </Center>

      <Accordion allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={600}>{ t('steps.auth_hold_info.faqs.title_1') }</Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>{ t('steps.auth_hold_info.faqs.text_1') }</AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={600}>{ t('steps.auth_hold_info.faqs.title_2') }</Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>{ t('steps.auth_hold_info.faqs.text_2') }</AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Flex justifyContent="right" mt="6">
        <Button
          variant="solid"
          size="lg"
          onClick={() => goForward()}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </>
  );
};

export default AuthHoldInfoStep
